import instagramSvg from './instagram-white.svg';
import logoSvg from './logo.svg';

function App() {
  return (
    <div className="">
      <div className="bg-black text-white text-center p-3 flex justify-center items-center flex-col py-24 px-5 h-screen">
        <img src={logoSvg} alt={"logo"} className="p-3 w-64" />
      </div>
      <div className="bg-gray-50 py-20 px-5 text-center">
        <div className="max-w-4xl m-auto">
          <div className="text-3xl flex flex-col gap-3 mb-10">
            <div>全てのブランド</div>
            <div>高価買取中</div>
          </div>
          <div className="flex flex-col gap-1 mb-10">
            <div>買取のお見積は無料でご相談可能です</div>
            <div>
              他に販売もしているので欲しいブランド、時計有ればお気軽にdm下さい
            </div>
          </div>

          <a
            href="https://instagram.com/michika_hirakawa"
            className="rounded-md flex items-center justify-center py-3 gap-2 text-white"
            style={{
              background:
                "radial-gradient(92.18% 99.11% at 26.56% 107.7%, #FFDD55 0%, #FFDD55 10%, #FF543E 50%, #C837AB 100%)",
            }}
          >
            <div className="flex justify-center items-center">
              <img src={instagramSvg} />
            </div>
            <div
              style={{
                fontWeight: 500,
              }}
            >
              DMで相談する
            </div>
          </a>
        </div>
      </div>
      <div className="max-w-4xl m-auto">
        <div className="bg-white py-20 px-5 text-center">
          <div className="text-3xl flex flex-col gap-3 mb-10">
            <div>買取事例</div>
          </div>

          <div className="flex gap-4 pb-5 border-b border-b-slate-100 mb-5">
            <div className="flex-1">
              <img
                src={`/images/image1.png`}
                alt={"image-1"}
                className="pr-3"
              />
            </div>
            <div
              className="flex justify-center flex-col items-start"
              style={{ flex: 2 }}
            >
              <div className="text-cyan-900">Hermes(エルメス)</div>
              <div className="text-cyan-900">バーキン35</div>
              <div className="text-cyan-900">ナチュラル</div>
              <div className="text-orange-300">買取実績: 1,200,000円</div>
            </div>
          </div>

          <div className="flex gap-4 pb-5 border-b border-b-slate-100 mb-5">
            <div className="flex-1">
              <img src={`/images/image2.png`} className="pr-3" />
            </div>
            <div
              className="flex justify-center flex-col items-start"
              style={{ flex: 2 }}
            >
              <div className="text-cyan-900">REMY MARTIN(レミーマルタン)</div>
              <div className="text-cyan-900">LOUIS XIII ルイ13世</div>
              <div className="text-cyan-900">THE ORIGIN-1874</div>
              <div className="text-orange-300">買取実績: 650,000円</div>
            </div>
          </div>

          <div className="flex gap-4 pb-5 border-b border-b-slate-100 mb-5">
            <div className="flex-1">
              <img src={`/images/image3.png`} className="pr-3" />
            </div>
            <div
              className="flex justify-center flex-col items-start"
              style={{ flex: 2 }}
            >
              <div className="text-cyan-900">ROLEX(ロレックス)</div>
              <div className="text-cyan-900">デイトナ</div>
              <div className="text-cyan-900">116500LN</div>
              <div className="text-orange-300">買取実績: 4,100,000円</div>
            </div>
          </div>

          <div className="flex gap-4 pb-5 border-b border-b-slate-100 mb-5">
            <div className="flex-1">
              <img src={`/images/image4.png`} className="pr-3" />
            </div>
            <div
              className="flex justify-center flex-col items-start"
              style={{ flex: 2 }}
            >
              <div className="text-cyan-900">Christian Louboutin</div>
              <div className="text-cyan-900">クリスチャンルブタン / 長財布</div>
              <div className="text-cyan-900">Panettone</div>
              <div className="text-orange-300">買取実績: 20,000円</div>
            </div>
          </div>

          <div className="flex gap-4 pb-5 border-b border-b-slate-100 mb-5">
            <div className="flex-1">
              <img src={`/images/image5.png`} className="pr-3" />
            </div>
            <div
              className="flex justify-center flex-col items-start"
              style={{ flex: 2 }}
            >
              <div className="text-cyan-900">CHANEL(シャネル)</div>
              <div className="text-cyan-900">ココマーク ピアス</div>
              <div className="text-orange-300">買取実績: 40,000円</div>
            </div>
          </div>

          <div className="flex gap-4 pb-5 border-b border-b-slate-100 mb-5">
            <div className="flex-1">
              <img src={`/images/image6.png`} className="pr-3" />
            </div>
            <div
              className="flex justify-center flex-col items-start"
              style={{ flex: 2 }}
            >
              <div className="text-cyan-900">CELINE(セリーヌ)</div>
              <div className="text-cyan-900">NANO LUGGAGE</div>
              <div className="text-orange-300">買取実績: 250,000円</div>
            </div>
          </div>

          <div className="flex gap-4 pb-5 border-b border-b-slate-100 mb-5">
            <div className="flex-1">
              <img src={`/images/image7.png`} className="pr-3" />
            </div>
            <div
              className="flex justify-center flex-col items-start"
              style={{ flex: 2 }}
            >
              <div className="text-cyan-900">Cartier(カルティエ)</div>
              <div className="text-cyan-900">USTE UN CLOU BRACELET，</div>
              <div className="text-cyan-900">SMALL MODEL</div>
              <div className="text-orange-300">買取実績: 250,000円</div>
            </div>
          </div>

          <div className="text-3xl flex flex-col gap-3 mb-10 mt-20">
            <div>取扱ジャンル</div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image1.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">洋服</div>
              <div className="text-gray-300">fashion</div>
            </div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image2.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">バッグ</div>
              <div className="text-gray-300">bag</div>
            </div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image3.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">アクセサリー・小物</div>
              <div className="text-gray-300">accessories</div>
            </div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image4.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">靴</div>
              <div className="text-gray-300">shoes</div>
            </div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image5.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">財布</div>
              <div className="text-gray-300">wallet</div>
            </div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image6.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">腕時計</div>
              <div className="text-gray-300">watch</div>
            </div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image7.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">金・プラチナ・貴金属</div>
              <div className="text-gray-300">gold</div>
            </div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image8.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">ダイヤモンド</div>
              <div className="text-gray-300">diamond</div>
            </div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image9.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">ジュエリー</div>
              <div className="text-gray-300">jewelry</div>
            </div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image10.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">コート</div>
              <div className="text-gray-300">coat</div>
            </div>
          </div>

          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <img src={`/images/sub-image11.png`} />
            </div>
            <div
              className="flex justify-center flex-col items-start pb-3 border-b border-b-slate-100"
              style={{ flex: 4 }}
            >
              <div className="text-cyan-900">お酒</div>
              <div className="text-gray-300">alcohol</div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black text-center text-white text-sm py-20 px-5">
        <a href="https://instagram.com/michika_hirakawa">
          <img
            src={instagramSvg}
            className="text-center inline-block w-6 mb-3"
          />
        </a>
        <div>株式会社Proud</div>
        <div>代表取締役: 平川三尋</div>
        <div>電話番号: 070-2383-0034</div>
        <div>東京都公安委員会古物商許可証 第451370013886号</div>
        <div>Copyright © Proud, Ltd. All rights reserved.</div>
      </div>
    </div>
  );
}

export default App;
